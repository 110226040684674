/* External dependencies */
import { Div, Text } from 'atomize';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import MainPopup from '@shared/ui/popup/MainPopup';
import Title from '@shared/ui/titles/Title';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import DownloadAppButtons from '../downloadAppButtons/DownloadAppButtons';
import { apps } from '../downloadAppButtons/helpers';

interface ReviewPopupProps {
  medcheckLogo: IGatsbyImageData;
  setReviewPopup: (v: boolean) => void;
}

function ReviewPopup({ medcheckLogo, setReviewPopup }: ReviewPopupProps) {
  const { t } = useTranslation();

  function onClose() {
    setReviewPopup(false);
  }
  return (
    <MainPopup
      onClose={onClose}
      maxW={{ xs: '70vw', md: '595px' }}
      title={<Title size="medium" title={t('reviews')} />}
    >
      <Div
        d="flex"
        flexDir="column"
        align="center"
        m={{ b: '2rem' }}
        className="download-app-block"
      >
        <GatsbyImage
          image={medcheckLogo}
          alt={'Reveiws of a phisician profile in the medcheck app'}
          objectFit="contain"
          className="download-app-image"
          style={{ maxWidth: '96px', width: '100%' }}
        />
        <Div m={{ t: '3rem' }}>
          <Text
            textSize="20px"
            textWeight="500"
            textAlign="center"
            style={{ whiteSpace: 'pre-line', lineHeight: '28px' }}
          >
            <Trans>downloadAppToLeaveAReview</Trans>
          </Text>

          <Div
            d="flex"
            m={{ t: '1.5rem' }}
            justify="center"
            style={{ gap: '8px' }}
          >
            {apps.map((app, index) => (
              <Div m={{ b: '8px' }} key={index}>
                <DownloadAppButtons
                  key={index}
                  link={app.link}
                  paddingButton={{ y: '0', x: '5px' }}
                  paddingRightForText="0"
                  store={app.store}
                  textSizeApp="15px"
                  textSizeDownload="6px"
                />
              </Div>
            ))}
          </Div>
        </Div>
      </Div>
    </MainPopup>
  );
}

export default ReviewPopup;
