/* External dependencies */
import { Col, Container, Div, Icon, Row, Text } from 'atomize';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useRef, useState } from 'react';

/* Local dependencies */
import { EntryType } from '@entities/users/lib/types';
import useOutsideClick from '@shared/custom-hooks/useOutsideClick';
import Button from '@shared/ui/button';
import CardWrapper from '@shared/ui/card-wrapper/CardWrapper';
import MainPopup from '@shared/ui/popup/MainPopup';
import Title from '@shared/ui/titles/Title';
import { getSlicedString } from '@shared/utils/getSlicedString';
import { formatYearLink } from '@shared/utils/renderDate';
import { getImage } from 'gatsby-plugin-image';
import ContactsCard from '../cards/ContactsCard';
import SecondaryMainText from '../cards/SecondaryMainText';
import UserMainInfoCard from '../cards/UserMainInfoCard';
import { apps } from '../downloadAppButtons/helpers';
import ReviewPopup from '../popup/ReviewPopup';
import WorkPlaces from '../workPlaces/WorkPlaces';
import { getExperience, getSlicedArray, getWorkPlaces } from './helpers';

export default function DoctorClinicDetails({ user }) {
  const { t } = useTranslation();
  const ref = useRef();
  const [isReviewPopup, setReviewPopup] = useState(false);
  const [isDescriptionPopup, setIsDescriptionPopup] = useState(false);
  const [isEducationPopup, setIsEducationPopup] = useState(false);
  const [isExperiencePopup, setIsExperiencePopup] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const data = useStaticQuery(query);
  const medcheckPhysicianProfile = getImage(data?.medcheckPhysicianProfile);
  const medcheckClinicProfile = getImage(data?.medcheckClinicProfile);
  const medcheckReviews = getImage(data?.medcheckReviews);
  const medcheckLogo = getImage(data?.medcheckLogo);

  const isPhisician = user?.typename === EntryType.PHYSICIAN;
  const textExperienceYear = getExperience(user?.experienceSince);
  const sliceNumber = 300;
  const sliceNumberForArray = 3;
  const workPlaces = getWorkPlaces(user);
  const educations = getSlicedArray(user?.educations);
  const workExperience =
    user?.workExperience?.length > sliceNumberForArray
      ? getSlicedArray(user?.workExperience)
      : user?.workExperience;
  const education = getSlicedString(user?.education, sliceNumber);
  const isDescriptionLengthMoreSliceNumber =
    user?.bio?.length > sliceNumber || user?.description?.length > sliceNumber;
  const description = isDescriptionLengthMoreSliceNumber
    ? getSlicedString(user?.bio || user?.description, sliceNumber)
    : user?.bio || user?.description;
  const experience = getSlicedString(user?.experience, sliceNumber);
  const educationsOrExperience = user?.educations || user?.workExperience;
  const titleForPopup = isDescriptionPopup
    ? isPhisician
      ? t(`aboutDoctor`)
      : t('aboutClinic')
    : isEducationPopup
    ? t('education')
    : isExperiencePopup && t('workExperience');
  const name = isPhisician
    ? `${user?.firstName !== null ? user?.firstName : ''} ${
        user?.lastName !== null ? user?.lastName : ''
      } ${user?.patronymic !== null ? user?.patronymic : ''}`
    : `${user?.clinicName}`;

  console.log(user);

  function openDescriptionPopup() {
    setIsDescriptionPopup(!isDescriptionPopup);
  }
  function openEducationPopup() {
    setIsEducationPopup(!isEducationPopup);
  }
  function openExperiencePopup() {
    setIsExperiencePopup(!isExperiencePopup);
  }

  useOutsideClick(ref, () => {
    closePopup();
  });

  function closePopup() {
    if (isEducationPopup) {
      setIsEducationPopup(false);
    }

    if (isExperiencePopup) {
      setIsExperiencePopup(false);
    }

    if (isDescriptionPopup) {
      setIsDescriptionPopup(false);
    }
  }

  function handleMouseEnter() {
    setIsHover(true);
  }
  function handleMouseLeave() {
    setIsHover(false);
  }

  return (
    <Div bg="#f3f6fa" p={{ b: { xs: '1rem', sm: '5rem' }, t: '165px' }}>
      <Container>
        <Div
          d="flex"
          m={{ b: '2rem' }}
          onClick={() => navigate(-1)}
          cursor="pointer"
        >
          <Text
            d="flex"
            align="center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            w="auto"
          >
            <Icon
              name="LeftArrow"
              color={isHover ? 'primary' : '#222b45'}
              size="40px"
            />
            <Text
              tag="span"
              textSize="display2"
              textColor={isHover && 'primary'}
            >
              <Trans>back</Trans>
            </Text>
          </Text>
        </Div>
        <Row justify="between">
          <Col size={{ xs: '12', md: '10', lg: '8' }}>
            <Div m={{ b: '1rem' }}>
              <UserMainInfoCard
                avatar={user?.avatar}
                experience={isPhisician && textExperienceYear}
                level={user?.level}
                physician={name}
                rating={user?.rating?.numreviews}
                specialities={isPhisician ? user?.specialities?.title_ru : null}
              />
            </Div>
            {workPlaces?.length ? (
              <Div d={{ xs: 'block', lg: 'none' }} m={{ b: '1rem' }}>
                <WorkPlaces workPlaces={workPlaces} />
              </Div>
            ) : null}
            {(user?.bio || user?.description) && (
              <Div m={{ b: '1rem' }}>
                <CardWrapper>
                  <Title
                    type="medium"
                    marginBottom="1.5rem"
                    title={isPhisician ? t(`aboutDoctor`) : t('aboutClinic')}
                  />
                  <Text m={{ b: '1.5rem' }}>{description}</Text>
                  {isDescriptionLengthMoreSliceNumber && (
                    <Div d="flex" w="fit-content">
                      <Button
                        typeColor="secondary"
                        textColor="grayTextInCard"
                        onClick={openDescriptionPopup}
                      >
                        <Trans>more</Trans>
                      </Button>
                    </Div>
                  )}
                </CardWrapper>
              </Div>
            )}
            {(user?.specialities?.title_ru ||
              user?.medicalProfiles?.title_ru) && (
              <Div m={{ b: '1rem' }}>
                <CardWrapper>
                  <Title
                    type="medium"
                    marginBottom="1.5rem"
                    title={t('specialization')}
                  />
                  <Div d="flex" flexWrap="wrap">
                    {isPhisician
                      ? user?.specialities?.title_ru?.map((item, index) => (
                          <Div
                            key={index}
                            bg="rgba(73, 91, 199, 0.12);"
                            rounded="twentyFour"
                            p="4px 10px"
                            m={{ r: '10px', b: '10px' }}
                          >
                            {item}
                          </Div>
                        ))
                      : user?.medicalProfiles?.title_ru?.map((item, index) => (
                          <Div
                            key={index}
                            bg="rgba(73, 91, 199, 0.12);"
                            rounded="twentyFour"
                            p="4px 10px"
                            m={{ r: '10px', b: '10px' }}
                          >
                            {item}
                          </Div>
                        ))}
                  </Div>
                </CardWrapper>
              </Div>
            )}
            {(user?.educations || user?.education) && (
              <Div m={{ b: '1rem' }}>
                <CardWrapper>
                  <Title
                    marginBottom="20px"
                    title={t('education')}
                    type="medium"
                  />
                  {educations?.map((item, index) => {
                    const periodFromEducation = formatYearLink(
                      item?.studyPeriod?.from,
                    );
                    const periodToEducation =
                      formatYearLink(item?.studyPeriod?.to) ||
                      'по настоящее время';

                    return (
                      <SecondaryMainText
                        key={index}
                        period={`${periodFromEducation} - ${periodToEducation}`}
                        secondaryText={item?.institution}
                        mainText={item?.department}
                      />
                    );
                  })}
                  {user?.education && (
                    <Text style={{ whiteSpace: 'pre-line' }}>{education}</Text>
                  )}
                  {(user?.education?.length > sliceNumber ||
                    user?.educations?.length > sliceNumberForArray) && (
                    <Div d="flex" w="fit-content" m={{ t: '1rem' }}>
                      <Button
                        typeColor="secondary"
                        textColor="grayTextInCard"
                        onClick={openEducationPopup}
                      >
                        <Trans>more</Trans>
                      </Button>
                    </Div>
                  )}
                </CardWrapper>
              </Div>
            )}
            {(user?.workExperience || user?.experience) && (
              <Div m={{ b: '1rem' }}>
                <CardWrapper>
                  <Title
                    marginBottom="20px"
                    title={t('workExperience')}
                    type="medium"
                  />
                  {workExperience?.map((item, index) => {
                    const periodFromWorkFrom = formatYearLink(
                      item?.workPeriod?.from,
                    );
                    const periodToWorkTo =
                      formatYearLink(item?.workPeriod?.to) ||
                      'по настоящее время';

                    return (
                      <SecondaryMainText
                        key={index}
                        period={`${periodFromWorkFrom} - ${periodToWorkTo}`}
                        secondaryText={item?.clinicName}
                        mainText={item?.position}
                      />
                    );
                  })}
                  {user?.experience && (
                    <Text style={{ whiteSpace: 'pre-line' }}>{experience}</Text>
                  )}
                  {(user?.experience?.length > sliceNumber ||
                    user?.workExperience?.length > sliceNumberForArray) && (
                    <Div d="flex" w="fit-content" m={{ t: '1rem' }}>
                      <Button
                        typeColor="secondary"
                        textColor="grayTextInCard"
                        onClick={openExperiencePopup}
                      >
                        <Trans>more</Trans>
                      </Button>
                    </Div>
                  )}
                </CardWrapper>
              </Div>
            )}
            <Div m={{ b: '1rem' }}>
              <ContactsCard
                alt={`A ${
                  isPhisician ? 'phisician' : 'clinic'
                } profile in the medcheck app`}
                apps={apps}
                medcheckProfileImage={
                  isPhisician ? medcheckPhysicianProfile : medcheckClinicProfile
                }
                medcheckLogo={medcheckLogo}
                title={t('contacts')}
                text={t('downloadTheAppToSeeDoctorsContacts')}
              />
            </Div>
            <Div m={{ b: '1rem' }}>
              <CardWrapper>
                <Title marginBottom="40px" title={t('reviews')} type="medium" />
                <Div textAlign="center">
                  <Text m={{ b: '1rem' }}>
                    <Trans>
                      {isPhisician ? 'haveBeenToTheDoctor' : 'haveBeenThere'}
                    </Trans>
                  </Text>
                  <Div m={{ b: '2rem' }} justify="center" d="flex">
                    <Button
                      typeColor="outlined"
                      textColor="primary"
                      width="auto"
                      textTransform="uppercase"
                      onClick={() => setReviewPopup(!isReviewPopup)}
                    >
                      <Trans>leaveComment</Trans>
                    </Button>
                  </Div>
                </Div>
              </CardWrapper>
            </Div>
          </Col>
          {workPlaces?.length ? (
            <Col
              d={{ xs: 'none', lg: 'block' }}
              size={{ xs: '12', md: '10', lg: '4' }}
            >
              <WorkPlaces workPlaces={workPlaces} />
            </Col>
          ) : null}
        </Row>
        {isReviewPopup && (
          <ReviewPopup
            medcheckLogo={medcheckLogo}
            setReviewPopup={setReviewPopup}
          />
        )}
        {(isDescriptionPopup || isEducationPopup || isExperiencePopup) && (
          <Div ref={ref}>
            <MainPopup
              onClose={closePopup}
              maxW={{ xs: '70vw', md: '595px' }}
              title={
                <Div m={{ l: '1rem' }}>
                  <Title title={titleForPopup} type="medium" />
                </Div>
              }
            >
              <Div p={{ x: '2rem' }}>
                {educationsOrExperience?.map((item, index) => {
                  const periodFrom = item?.studyPeriod
                    ? formatYearLink(item?.studyPeriod?.from)
                    : formatYearLink(item?.workPeriod?.from);
                  const periodTo = item?.studyPeriod
                    ? formatYearLink(item?.studyPeriod?.to)
                    : formatYearLink(item?.workPeriod?.to) ||
                      'по настоящее время';

                  return (
                    <SecondaryMainText
                      key={index}
                      period={`${periodFrom} - ${periodTo}`}
                      secondaryText={
                        item?.institution ? item?.institution : item?.clinicName
                      }
                      mainText={
                        item?.department ? item?.department : item?.position
                      }
                    />
                  );
                })}
                {(user?.education ||
                  user?.experience ||
                  user?.bio ||
                  user?.description ||
                  user?.workExperience) && (
                  <Div p={{ b: '1rem' }}>
                    <Text style={{ whiteSpace: 'pre-line' }}>
                      {isDescriptionPopup && (user?.bio || user?.description)}
                      {isEducationPopup && user?.education}
                      {isExperiencePopup &&
                        (user?.experience || user?.workExperience)}
                    </Text>
                  </Div>
                )}
              </Div>
            </MainPopup>
          </Div>
        )}
      </Container>
    </Div>
  );
}

export const query = graphql`
  query {
    medcheckPhysicianProfile: file(
      relativePath: { eq: "medcheck-physician-profile-with-bg-qrcode.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    medcheckClinicProfile: file(
      relativePath: { eq: "medcheck-clinic-profile-with-bg-qrcode.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    medcheckReviews: file(
      relativePath: { eq: "medcheck-reviews-with-bg-qrcode.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    medcheckLogo: file(relativePath: { eq: "medcheckLogo.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
