// External dependencies
import { Div, Text } from 'atomize';
import React from 'react';

// Local dependencies
import './style.scss';

export interface SecondaryMainTextProps {
  period: any;
  secondaryText: string;
  mainText: string;
}

export default function SecondaryMainText({
  period,
  secondaryText,
  mainText,
}: SecondaryMainTextProps) {
  return (
    <Div className="specialization-card-item">
      {period && (
        <Text textColor="grayTextInCard" tag="h5" m={{ b: '8px' }}>
          {period}
        </Text>
      )}
      <Text>
        {secondaryText}
        {secondaryText && ', '}
        {mainText}
      </Text>
    </Div>
  );
}
