// External dependencies
import { Div, Text } from 'atomize';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

// Local dependencies
import useOutsideClick from '@shared/custom-hooks/useOutsideClick';
import Button from '@shared/ui/button';
import CardWrapper from '@shared/ui/card-wrapper/CardWrapper';
import MainPopup from '@shared/ui/popup/MainPopup';
import Title from '@shared/ui/titles/Title';
import { addSpaceAfterCommaInString } from '@shared/utils/addSpaceAfterCommaInString';
import { Service } from '../doctors-clinics/types';

export interface ListServices {
  allServices?: boolean;
  title: string;
  services: Service[];
}

export default function ListServices({
  services,
  allServices,
  title,
}: ListServices) {
  const [showMoreServices, setShowMoreServices] = useState(false);

  const ref = useRef();
  const { t } = useTranslation();

  useOutsideClick(ref, () => {
    setShowMoreServices(false);
  });

  const firstServices = services?.slice(0, 5);

  if (!services?.length) {
    return (
      <>
        <Title marginBottom="20px" title={t(title)} />
        <Text>
          {' '}
          <Trans>notSpecified</Trans>
        </Text>
      </>
    );
  }

  return (
    <>
      <Title marginBottom="20px" title={t(title)} />
      {!allServices ? (
        <Div m={{ b: '25px' }}>
          {firstServices?.length
            ? firstServices.map((service, index) => {
                const isFromPrice =
                  service?.price?.isFrom && service?.price?.value ? 'от' : '';
                const nameWithSpaces = addSpaceAfterCommaInString(
                  service?.name,
                );

                return (
                  <React.Fragment key={`${service?.name}-${index}`}>
                    {service?.name ? (
                      <Div className="specialization-card-item">
                        {service?.price && (
                          <Text
                            textColor="grayTextInCard"
                            tag="h5"
                            m={{ b: '8px' }}
                          >
                            {`${isFromPrice} ${service?.price?.value || ''}`}{' '}
                            сом
                          </Text>
                        )}

                        <Text>{nameWithSpaces}</Text>
                      </Div>
                    ) : (
                      <Trans>notSpecified</Trans>
                    )}
                  </React.Fragment>
                );
              })
            : null}
        </Div>
      ) : (
        <Div>
          {services?.length
            ? services?.map((service, index) => {
                const isFromPrice =
                  service?.price?.isFrom && service?.price?.value ? 'от' : '';
                const nameWithSpaces = addSpaceAfterCommaInString(
                  service?.name,
                );

                return (
                  <React.Fragment key={`${service?.name}-${index}`}>
                    {service?.name ? (
                      <Div className="specialization-card-item">
                        {service?.price && (
                          <Text
                            textColor="grayTextInCard"
                            tag="h5"
                            m={{ b: '8px' }}
                          >
                            {`${isFromPrice} ${service?.price?.value || ''} `}{' '}
                            сом
                          </Text>
                        )}

                        <Text>{nameWithSpaces}</Text>
                      </Div>
                    ) : (
                      <Trans>notSpecified</Trans>
                    )}
                  </React.Fragment>
                );
              })
            : null}
        </Div>
      )}
      {!allServices && services?.length > 5 && (
        <Div maxW="200px" m="0 auto">
          <Button
            onClick={() => setShowMoreServices(true)}
            typeColor="secondary"
            textColor="grayTextInCard"
          >
            <Trans>knowMore</Trans>
          </Button>
        </Div>
      )}
      {showMoreServices && (
        <Div ref={ref}>
          <MainPopup minW="600px" onClose={() => setShowMoreServices(false)}>
            <CardWrapper>
              <ListServices services={services} title={title} allServices />
            </CardWrapper>
          </MainPopup>
        </Div>
      )}
    </>
  );
}
