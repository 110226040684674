export function getSlicedString(string: String, length: number) {
  if (string?.length > length) {
    const slicedStr = string?.slice(0, length);
    const index = slicedStr?.lastIndexOf(' ');
    const result = index !== -1 ? string?.slice(0, index) : string;

    return result + '...';
  } else {
    return string;
  }
}
