// External dependencies
import { Div } from 'atomize';
import React, { useRef, useState } from 'react';

// Local dependencies
import useOutsideClick from '@shared/custom-hooks/useOutsideClick';
import ListServices from '../listServices/ListServices';
import PickupLocationsAndAddress from '../pickupLocationsAndAddress/PickupLocationsAndAddress';
import ScheduleBlock from '../schedule/ScheduleBlock';
import TodayWorkDate from '../schedule/TodayWorkdate';
import { WorkPlace } from '../doctors-clinics/types';
import CardWrapper from '@shared/ui/card-wrapper/CardWrapper';
import MainPopup from '@shared/ui/popup/MainPopup';
import { getDaysWithData } from '../doctors-clinics/helpers';

export interface WorkPlacesProps {
  workPlaces: WorkPlace[];
}

export default function WorkPlaces({ workPlaces }: WorkPlacesProps) {
  const [selectedWorkPlace, setSelectedWorkPlace] = useState(workPlaces[0]);
  const [showFullSchedule, setShowFullSchedule] = useState(false);
  const ref = useRef();
  const workDays = getDaysWithData(selectedWorkPlace?.workDays);
  useOutsideClick(ref, () => {
    setShowFullSchedule(false);
  });

  return (
    <Div>
      <CardWrapper>
        <Div m={{ b: '16px' }}>
          <PickupLocationsAndAddress
            workPlaces={workPlaces}
            selectedWorkPlace={selectedWorkPlace}
            selectWorkPlace={setSelectedWorkPlace}
          />
        </Div>
        <Div
          border={{ y: '1px solid ' }}
          m={{ x: '-15px', b: '16px' }}
          borderColor="bg_sky_color"
          p={{ x: '15px', y: '16px' }}
          cursor={workDays && 'pointer'}
        >
          <TodayWorkDate
            onClick={() => setShowFullSchedule((prevValue) => !prevValue)}
            schedule={workDays}
          />
        </Div>
        {showFullSchedule && workDays && (
          <Div ref={ref}>
            <MainPopup
              minH="500px"
              minW="600px"
              onClose={() => setShowFullSchedule(false)}
            >
              <ScheduleBlock schedule={workDays} />
            </MainPopup>
          </Div>
        )}
        <ListServices services={selectedWorkPlace?.services} title="services" />
      </CardWrapper>
    </Div>
  );
}
