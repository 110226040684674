/* Local dependencies */
import { DownloadAppButton } from './DownloadAppButtons';

export const apps: DownloadAppButton[] = [
  {
    store: 'apple',
    link: 'https://apps.apple.com/kg/app/medcheck/id1578873351',
  },
  {
    store: 'android',
    link: 'https://play.google.com/store/search?q=medcheck&c=apps&hl=ru',
  },
];
