// External dependencies
import { Div, Row, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';

// Local dependencies
import CustomDropdown from '@shared/ui/custom-dropdown/CustomDropdown';
import FormField from '@shared/ui/form/FormField';
import { WorkPlace } from '../doctors-clinics/types';
import Locations from './Locations';

interface PickupLocationsAndAddressProps {
  workPlaces: WorkPlace[];
  selectedWorkPlace: WorkPlace;
  selectWorkPlace: Dispatch<SetStateAction<WorkPlace>>;
}

export default function PickupLocationsAndAddress({
  workPlaces,
  selectWorkPlace,
  selectedWorkPlace,
}: PickupLocationsAndAddressProps) {
  const ref = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const address =
    selectedWorkPlace?.clinicAddress?.country &&
    selectedWorkPlace?.clinicAddress?.city
      ? `${selectedWorkPlace?.clinicAddress?.country} ${selectedWorkPlace?.clinicAddress?.city} ${selectedWorkPlace?.clinicAddress?.address}`
      : selectedWorkPlace?.clinicAddress?.address;

  const handleMouseOver = () => {
    setIsPopoverOpen(true);
  };

  const handleMouseOut = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Div bg="white" m={{ t: '20px' }} maxW="400px">
      <Text textWeight="600" textSize="display2" p={{ b: '20px' }}>
        <Trans>receptionPlaces</Trans> ({workPlaces?.length})
      </Text>
      {workPlaces?.length > 1 ? (
        <CustomDropdown
          items={workPlaces}
          minWidth="auto"
          selectedItem={selectedWorkPlace?.clinicName}
          handleSelectItem={(item) => selectWorkPlace(item)}
        />
      ) : (
        <Popover
          isOpen={isPopoverOpen}
          positions={['bottom']}
          content={({ childRect, popoverRect }) => (
            <ArrowContainer
              position="bottom"
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={'#EDF1F7'}
              arrowSize={6}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
              arrowStyle={{ top: '-12px' }}
            >
              <Div
                bg="#EDF1F7"
                w="100%"
                p="5px 10px"
                m={{ t: '-12px' }}
                rounded="4px"
                maxW="300px"
                textSize="small"
              >
                {workPlaces[0]?.clinicName}
              </Div>
            </ArrowContainer>
          )}
        >
          <Row onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <Div p={{ l: '1rem' }}>
              <Text>{workPlaces[0]?.clinicName}</Text>
            </Div>
          </Row>
        </Popover>
      )}
      <Div m={{ t: '20px' }}>
        <Text textWeight="600" textSize="display2" p={{ b: '20px' }}>
          <Trans>address</Trans>
        </Text>
        <Locations
          link={`https://2gis.kg/bishkek/search/${address}`}
          location={selectedWorkPlace?.clinicAddress?.address}
        />
      </Div>
    </Div>
  );
}
