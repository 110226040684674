// External dependencies
import { Div, Icon } from 'atomize';
import React, { useRef, useState } from 'react';

// Local dependencies
import useOutsideClick from '@shared/custom-hooks/useOutsideClick';

interface CustomDropdownProps {
  items: { value?: string; clinicName: string }[];
  minWidth?: string;
  selectedItem: string;
  handleSelectItem: (item) => void | any;
}

export default function CustomDropdown({
  items,
  minWidth = '280px',
  selectedItem,
  handleSelectItem,
}: CustomDropdownProps) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsDropdownVisible(false);
  });

  function onSelectItem(item) {
    handleSelectItem(item);
    setIsDropdownVisible(false);
  }

  return (
    <Div
      ref={ref}
      bg="gray"
      rounded="xl"
      cursor="pointer"
      pos="relative"
      w="100%"
    >
      <Div
        d="flex"
        minW={minWidth}
        justify="space-between"
        p="10px 16px"
        className="custom-dropdown"
        onClick={() => setIsDropdownVisible((prevValue) => !prevValue)}
        style={{ gap: '10px' }}
      >
        <Div>
          {selectedItem?.length > 30
            ? `${selectedItem.slice(0, 30)}...`
            : selectedItem}
        </Div>
        <Icon
          transform={isDropdownVisible && 'rotate(180deg)'}
          name="DownArrow"
          size="20px"
          m={{ t: '2px' }}
        />
      </Div>
      <Div
        bg="white"
        rounded="md"
        pos="absolute"
        w="100%"
        left="0"
        top="110%"
        className="dropdown-body"
        transform={`scale(1, ${isDropdownVisible ? 1 : 0})`}
        shadow="3"
        overflow="hidden"
        transition="0.3s"
        style={{
          transformOrigin: 'top',
        }}
      >
        {items.map((item, index) => (
          <Div
            key={index}
            p="10px"
            hoverBg="gray"
            className="dropdown-item"
            textColor={
              selectedItem !== item.name
                ? 'grayTextInCard'
                : 'table_cell_text_color'
            }
            cursor="pointer"
            textSize="smallSize2"
            onClick={() => onSelectItem(item)}
          >
            {item.clinicName}
          </Div>
        ))}
      </Div>
    </Div>
  );
}
