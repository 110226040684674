/* External dependencies */
import { Text } from 'atomize';
import React from 'react';

interface TitleProps {
  marginBottom?: string;
  title?: any;
  type?: 'small' | 'medium' | 'large';
  size?: string;
}

export default function Title({
  marginBottom = '8px',
  title,
  type,
  size,
}: TitleProps) {
  const fontSize =
    type === 'small' ? 'display1' : type === 'medium' ? 'medium' : 'display1';
  return (
    <Text
      textSize={size || fontSize}
      textWeight="700"
      m={{ b: marginBottom }}
      tag="h2"
    >
      {title}
    </Text>
  );
}
