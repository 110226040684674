// Local dependencies
import { Div, Image, Text } from 'atomize';
import React from 'react';
import ReactStars from 'react-stars';

// Local dependencies
import CardWrapper from '@shared/ui/card-wrapper/CardWrapper';
import DefaultAvatarIcon from '../../../svg/DefaultAvatarIcon';
import './style.scss';

interface UserMainInfoCard {
  avatar?: string;
  experience?: any;
  level?: string;
  physician: string;
  rating?: number;
  specialities: any;
}

export default function UserMainInfoCard({
  avatar,
  experience,
  level,
  physician,
  rating,
  specialities,
}: UserMainInfoCard) {
  const primarySize = '10px';

  return (
    <CardWrapper>
      <Div d={{ xs: 'block', sm: 'flex' }} style={{ gap: '16px' }}>
        {avatar ? (
          <Image
            w="144px"
            h="144px"
            rounded="50%"
            m={{ b: { xs: primarySize, sm: '0' } }}
            src={avatar}
            style={{ objectFit: 'contain' }}
          />
        ) : (
          <DefaultAvatarIcon />
        )}
        <Div>
          <Text
            textSize="display2"
            textWeight="600"
            p={{ b: primarySize }}
            d="inline"
          >
            {physician}
          </Text>
          <Div p={{ b: '5px' }}>
            {specialities?.map((item, index) => (
              <Text key={index} tag="span" d="inline">
                {item}
                {index !== specialities?.length - 1 && ', '}
              </Text>
            ))}
          </Div>
          <Div p={{ b: primarySize }}>
            {experience && (
              <Text tag="span" d="inline">
                {experience}
              </Text>
            )}
            {experience && level && (
              <Text
                tag="span"
                style={{ verticalAlign: 'text-bottom' }}
                d="inline"
                m={{ x: '5px' }}
                textWeight="700"
              >
                .
              </Text>
            )}
            {level && (
              <Text tag="span" d="inline">
                {level}
              </Text>
            )}
          </Div>
          {rating && (
            <ReactStars
              count={5}
              value={rating}
              size={18}
              edit={false}
              color2="#FD6F96"
            />
          )}
        </Div>
      </Div>
    </CardWrapper>
  );
}
