/* External dependencies */
import moment from 'moment';

export const DATE_FORMAT: string = 'DD.MM.YYYY';
export const DATE_FORMAT_FULL_YEAR: string = 'DD.MM.YYYY';
export const DATE_FORMAT_FOR_LINK = 'DD-MM-YYYY';
// The default timezone should point to Bishkek, Kyrgyzstan.
export const DEFAULT_TIMEZONE: string = '+06:00';
export const TIME_FORMAT: string = 'HH:mm';

export function formatDateLink(date: any) {
  return moment
    .unix(date)
    .utcOffset(DEFAULT_TIMEZONE)
    .format(DATE_FORMAT_FOR_LINK);
}

export function formatYearLink(date: any) {
  const stringDate = date + '';

  const dateTime = `${moment(date)
    .utcOffset(DEFAULT_TIMEZONE)
    .format(DATE_FORMAT)}`;

  const dateString = moment.unix(date).year();

  if (stringDate.length > 10) {
    return dateTime;
  }

  if (date === null) {
    return;
  }

  return dateString;
}

export function getDateTime(date) {
  const stringDate = date + '';

  const dateTime = `${moment(date)
    .utcOffset(DEFAULT_TIMEZONE)
    .format(DATE_FORMAT)}`;

  const dateString = moment.unix(date).format('DD.MM.YYYY');

  if (stringDate.length > 10) {
    return dateTime;
  }

  return dateString;
}
