// Exterlan dependencies
import { Div, Text, Icon } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import React from 'react';

// Local dependencies
import { WorkDays } from '../doctors-clinics/types';
import './style.scss';
import { weekDays } from './models';
import { getTodaySchedule, getWorkTime } from '../doctors-clinics/helpers';

interface ScheduleBlockProps {
  schedule: WorkDays;
}

export default function ScheduleBlock({ schedule }: ScheduleBlockProps) {
  const currentDaySchedule = getTodaySchedule(schedule);

  return (
    <Div p={{ xs: '10px', sm: { x: '15px', y: '20px' } }}>
      <Div
        d="flex"
        align="center"
        m={{ b: '24px', l: '-40px' }}
        justify="center"
        style={{ gap: '5px' }}
      >
        <Text tag="h3" textSize="medium">
          <Trans>workSchedule</Trans>
        </Text>
      </Div>
      <Div className="schedule-block">
        <Text pos="relative">
          <Trans>today</Trans>
          <Div
            pos="absolute"
            top="0"
            left={{ xs: '-30px', sm: '-50px' }}
            d={{ xs: 'none', sm: 'block' }}
            tag="span"
          >
            <Icon name="TimestampSolid" size="25px" color="#6574CF" />
          </Div>
        </Text>
        <Div>
          <Text>{currentDaySchedule}</Text>
        </Div>

        {weekDays.map((day, index) => {
          const currentDay = schedule[day];

          if (!currentDay) return null;

          const isOffTime = currentDay?.workingHours?.length === 0;

          return (
            <React.Fragment key={index}>
              <Text textWeight="600">
                <Trans>{day}</Trans>
              </Text>
              <Div>
                {currentDay?.workingHours?.map((workingTime, i) => {
                  const startTime = getWorkTime(workingTime?.from);
                  const finishTime = getWorkTime(workingTime?.to);

                  return <Div key={i}>{`${startTime} - ${finishTime}`}</Div>;
                })}
                {isOffTime && <Text>-</Text>}
              </Div>
            </React.Fragment>
          );
        })}
        <Div className="line-for-schedule"></Div>
        <Div className="line-for-schedule"></Div>
        <Text textWeight="600">
          <Trans>breakTime</Trans>:
        </Text>
        <Div>12:00 – 13:00</Div>
      </Div>
    </Div>
  );
}
