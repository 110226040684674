// External dependencies
import { Div, Text } from 'atomize';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

// Local dependencies
import CardWrapper from '@shared/ui/card-wrapper/CardWrapper';
import DownloadAppButtons, {
  DownloadAppButton,
} from '../downloadAppButtons/DownloadAppButtons';
import './style.scss';

interface ContactsCardProps {
  alt?: string;
  apps?: DownloadAppButton[];
  medcheckProfileImage?: IGatsbyImageData;
  medcheckLogo?: IGatsbyImageData;
  title?: string;
  text?: string;
}

export default function ContactsCard({
  alt,
  apps,
  medcheckProfileImage,
  medcheckLogo,
  title,
  text,
}: ContactsCardProps) {
  return (
    <CardWrapper>
      <Text textSize="medium" m={{ b: '40px' }} textWeight="700" tag="h2">
        {title}
      </Text>
      <Div d="flex" flexDir="column" align="center">
        <Div
          className="download-app-block"
          pos="relative"
          d={{ xs: 'none', sm: 'flex' }}
          justify={{ xs: 'flex-end', sm: 'center' }}
          m={{ b: '20px', xs: '0' }}
        >
          <GatsbyImage
            image={medcheckProfileImage}
            alt={alt}
            objectFit="contain"
            className="download-app-image"
            style={{ maxWidth: '400px', width: '100%' }}
          />
          <Div
            m={{ l: { sm: '-20px' } }}
            d={{ xs: 'none', sm: 'block' }}
            pos="absolute"
            bottom="20px"
            left="0"
          >
            {apps.map((app, index) => (
              <Div m={{ b: '8px' }} key={index}>
                <DownloadAppButtons
                  key={index}
                  link={app.link}
                  paddingButton={{ y: '0', x: '5px' }}
                  paddingRightForText="0"
                  store={app.store}
                  textSizeApp="15px"
                  textSizeDownload="6px"
                />
              </Div>
            ))}
          </Div>
        </Div>
        <Div
          d={{ xs: 'flex', sm: 'none' }}
          justify="space-between"
          style={{ gap: '20px' }}
          m={{ b: '3rem' }}
        >
          <GatsbyImage
            image={medcheckLogo}
            alt={'Reveiws of a phisician profile in the medcheck app'}
            objectFit="contain"
            className="download-app-image"
            style={{ maxWidth: '96px', width: '100%' }}
          />
        </Div>
        <Text
          style={{ whiteSpace: 'pre-line', lineHeight: '30px' }}
          textSize={{ xs: '20px', sm: '1.5rem' }}
          textWeight="500"
          textAlign="center"
        >
          {text}
        </Text>
        <Div
          d={{ xs: 'flex', sm: 'none' }}
          justify="space-between"
          style={{ gap: '0.5rem' }}
          m={{ t: '2rem' }}
        >
          {apps.map((app, index) => (
            <Div m={{ b: '8px' }} key={index}>
              <DownloadAppButtons
                key={index}
                link={app.link}
                paddingButton={{ y: '0', x: '5px' }}
                paddingRightForText="0"
                store={app.store}
                textSizeApp="15px"
                textSizeDownload="8px"
              />
            </Div>
          ))}
        </Div>
      </Div>
    </CardWrapper>
  );
}
