// External dependencies
import { Anchor, Text } from 'atomize';
import React from 'react';

// Local dependencies
import LocationIcon from '../../../svg/LocationIcon';

interface LocationsProps {
  link: string;
  location: string;
}

export default function Locations({ link, location }: LocationsProps) {
  return (
    <Anchor href={link} target="_blank" d="flex" style={{ gap: '16px' }}>
      <LocationIcon />
      <Text>{location}</Text>
    </Anchor>
  );
}
