/* External dependencies */
import { graphql } from 'gatsby';
import React from 'react';

/* Local dependencies */
import DoctorClinicDetails from '@components/website/doctors-clinics/DoctorClinicDetails';
import Footer from '@components/website/footer/Footer';
import Header from '@components/website/header/Header';
import Layout from '@shared/ui/layout/WebLayout';
import SEO from '@shared/ui/layout/seo';
import {
  extractCountryAndCity,
  extractMedServices,
  getFormattedSEOTitle,
} from '@shared/utils/helpers';

export default function User({ data }) {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = data || '';

  const user = JSON.parse(data?.user?.internal?.content);
  const countryAndCity = extractCountryAndCity(user);
  const medServices = extractMedServices(user);
  const specialist = user?.specialities?.title_ru
    ?.map((item) => item)
    .join(', ');
  const description = `${specialist} ${medServices}`;
  const title = getFormattedSEOTitle(user, specialist, countryAndCity);

  const avatar = user?.avatar ? user?.avatar : `${siteUrl}/medcheck.png`;

  return (
    <Layout>
      <SEO
        title={title}
        description={user?.specialities?.title_ru?.length > 0 && description}
        secondDescription={medServices}
        image={avatar}
      />
      <Header />
      <DoctorClinicDetails user={user} />
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query ($id: String, $language: String) {
    locale {
      internal {
        content
      }
      language
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    user(id: { eq: $id }) {
      internal {
        content
      }
    }
  }
`;
