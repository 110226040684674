// Exterlan dependencies
import { Div, Icon, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import { getTodaySchedule } from '../doctors-clinics/helpers';
import { WorkDays } from '../doctors-clinics/types';
import './style.scss';

interface ScheduleBlockProps {
  schedule: WorkDays;
  onClick;
}

export default function TodayWorkDate({
  schedule,
  onClick,
}: ScheduleBlockProps) {
  if (!schedule) {
    return (
      <Div p={{ xs: { y: '10px' }, sm: { y: '20px' } }}>
        <Text tag="h3" textSize="medium" m={{ b: '25px' }}>
          <Trans>workSchedule</Trans>
        </Text>
        <Text>
          <Trans>notSpecified</Trans>
        </Text>
      </Div>
    );
  }

  const currentDaySchedule = getTodaySchedule(schedule);

  return (
    <Div onClick={onClick} p={{ xs: { y: '10px' }, sm: { y: '20px' } }}>
      <Text tag="h3" textSize="medium" m={{ b: '25px' }}>
        <Trans>workSchedule</Trans>
      </Text>
      <Div d="flex" style={{ gap: '16px' }}>
        <Icon name="TimestampSolid" size="25px" color="#6574CF" />
        <>
          <Text>
            <Trans>today</Trans>
          </Text>
          <Div>{`${currentDaySchedule}`}</Div>
        </>

        <Icon name="DownArrow" size="20px" m={{ t: '2px' }} />
      </Div>
    </Div>
  );
}
